<template>
  <div class="flex flex-col gap-y-2">
    <div id="inkaso-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="inkaso-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_from"
                labelAddon="Od"
                :markDirty="true"
                :error="saveErrors.date_from"
              />

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_to"
                labelAddon="Do"
                :markDirty="true"
                :error="saveErrors.date_to"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setInitDates" class="tw--btn tw--btn-secondary">domyślnie</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">

              <div class="flex">
                <button @click="setReportType('0')"
                  class="tw--btn rounded-r-none"
                  :class="activeFilters.settled === '1' ? 'tw--btn-secondary' : 'disabled:opacity-100'"
                  :disabled="activeFilters.settled === '0'"
                >do rozliczenia</button>
                <button @click="setReportType('1')"
                  class="tw--btn rounded-l-none"
                  :class="activeFilters.settled === '0' ? 'tw--btn-secondary' : 'disabled:opacity-100'"
                  :disabled="activeFilters.settled === '1'"
                >rozliczone</button>
              </div>

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.employee_id"
                label="Agent"
                :options="employeeSimpleList"
                :nullOptionName="showAdmin ? 'wszyscy' : showManager ? 'zespół' : ''"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.insurer_id"
                label="TU"
                :options="insurerList"
              />
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setInitDates" class="tw--btn tw--btn-secondary">domyślnie</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="initList" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
        <button v-if="showAdmin && activeFilters.settled === '0'" @click="checkAll" class="tw--btn-icon flex items-center gap-1" title="Zanzacz/odznacz wszystko">
          <FontAwesomeIcon :icon="checkedAll ? ['far', 'minus-square'] : ['far', 'check-square']" size="lg" class="p-0" />
          <!-- <span>{{ checkedAll ? 'usuń zanzaczenie' : 'zaznacz wszystkie' }}</span> -->
        </button>
        <button v-if="showAdmin && activeFilters.settled === '0'" @click="setSettledSelected" class="tw--btn" :disabled="rowSelected === 0">
          <FontAwesomeIcon icon="clipboard-check" /> Oznacz jako 'rozliczone'
        </button>
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">znaleziono</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ rows.length }} rat</span>
        </div>
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">suma</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ inkasoSum | moneyPL }}</span>
        </div>
      </div>
    </div>

    <div id="inkaso-report-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

      <div id="inkaso-report-table" class="flex flex-col gap-1">
        <div class="flex flex-wrap border dark:border-gray-600 rounded shadow py-1" v-for="row in rows" :key="row.id">

          <div v-if="showAdmin && activeFilters.settled === '0'" class="order-1 w-1/2 md:w-8 flex items-center md:justify-center px-2">
            <label><input type="checkbox" true-value="1" false-value="0" v-model="row.checked"></label>
          </div>

          <div class="order-3 w-full md:w-56 flex items-center gap-x-1 px-2">
            <FontAwesomeIcon icon="user-tie" class="text-muted" />
            <div class="whitespace-nowrap overflow-x-hidden">{{ row.employee_name }}</div>
          </div>

          <div class="order-4 md:order-5 w-1/2 md:w-32 flex items-center gap-x-1 px-2" :title="activeFilters.settled === '0' ? 'data pobrania' : 'data rozliczenia'">
            <div>
              <FontAwesomeIcon v-if="activeFilters.settled === '0'" icon="hand-holding-usd" class="text-muted" />
              <FontAwesomeIcon v-else icon="clipboard-check" class="text-muted" />
            </div>
            <div>{{ activeFilters.settled === '0' ? row.inkaso_date_h : row.settled_date_h }}</div>
          </div>

          <div class="order-5 md:order-4 w-1/2 md:w-32 flex items-center justify-end px-2">{{ row.value | moneyPL }}</div>

          <div class="order-6 w-96 flex flex-wrap items-center gap-x-2 px-2">
            <div class="text-muted">klient</div>
            <router-link :to="{ name: 'Customer', params: { id: row.customer_id } }" class="tw--link whitespace-nowrap overflow-x-hidden" target="_blank">
              {{ row.customer_name }}
            </router-link>
          </div>

          <div class="order-7 flex-grow flex flex-col 2xl:flex-row px-2">
            <div class="flex-grow flex flex-wrap items-center gap-x-1">
              <div class="text-muted text">polisa:</div>
              <router-link :to="{ name: 'Order', params: { id: row.order_id } }" class="tw--link" target="_blank">
                {{ row.policy_no }}
              </router-link>
            </div>
            <div class="w-48">{{ row.insurer_name }}</div>
          </div>


          <div v-if="showAdmin && activeFilters.settled === '0'" class="order-2 md:order-9 w-1/2 md:w-auto flex gap-x-1 justify-end px-2">
            <button @click="setSettled(row.id)" class="tw--btn-icon" title="zatwierdź rozlicznie gotówki dla raty">
              <FontAwesomeIcon :icon="['fas', 'clipboard-check']" class="text-xl" />
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import moment from 'moment';
import ReportsService from '../../services/ReportsService';
import OrderService from '../../services/OrderService';

export default {
  name: 'InkasoReport',
  mixins: [confirm, errorHandler],
  data() {
    return {
      rows: [],
      activeFilters: {
        date_from: '',
        date_to: '',
        employee_id: '',
        insurer_id: '',
        settled: '0',
      },
      loading: false,
      saveErrors: {},
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapGetters('products', [
      'insurerList',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    showManager() {
      return this.currentUserType === 'manager';
    },
    inkasoSum() {
      return this.rows.reduce((a, b) => ({ value: Number(a.value) + Number(b.value) }), { value: 0 }).value;
    },
    rowSelected() {
      return this.rows.filter(obj => obj.checked === '1').length;
    },
    checkedAll() {
      if (this.rows.find(obj => obj.checked === '0' || obj.checked === undefined) === undefined) {
        return true;
      }
      return false;
    },
    difference() {
      let rDiff = { type: '', value: 0 };
      if (this.activeFilters.date_from !== '' && this.activeFilters.date_to !== '') {
        const a = moment(this.activeFilters.date_from);
        const b = moment(this.activeFilters.date_to);
        const dDiff = b.diff(a, 'days');
        let mDiff = 0;
        if (dDiff > 26) {
          mDiff = b.diff(a, 'months');
          rDiff = { type: 'months', value: mDiff + 1 };
        } else {
          rDiff = { type: 'days', value: dDiff + 1 };
        }
      }
      return rDiff;
      // return { type: '', value: 0 };
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    this.initList();
  },
  methods: {
    initList() {
      // Filtry
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      if (this.activeFilters.date_from === '' || this.activeFilters.date_to === '') {
        this.setToday();
      }
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      // this.setUrl();
      ReportsService.getInkasoReport(this.activeFilters)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rows = response.data.rows;
          } else {
            this.$store.dispatch('setTemporaryAlert', { message: 'Wystapił niespodziewany błąd!', type: 'danger', time: 6 });
          }
          this.$store.dispatch('setLoadingData', false);
          this.setUrl();
        })
        .catch((error) => {
          this.rows = [];
          if (error.response !== undefined) {
            console.error(error.response.data.result);
            this.saveErrors = error.response.data.errors;
            this.$store.dispatch('setTemporaryAlert', { message: error.response.data.msg, type: 'danger', time: 6 });
          } else {
            this.$store.dispatch('setTemporaryAlert', { message: 'Błąd generowania raportu - informacja o błędzie w konsoli', type: 'danger', time: 6 });
            console.error(error);
          }
          this.$store.dispatch('setLoadingData', false);
          this.setUrl();
        });
    },
    prevMonth() {
      if (this.activeFilters.date_from !== '') {
        this.activeFilters.date_from = moment(this.activeFilters.date_from).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      }
      if (this.activeFilters.date_to !== '') {
        this.activeFilters.date_to = moment(this.activeFilters.date_to).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      }
    },
    setInitDates() {
      this.setToday();
      this.getReport();
    },
    setToday() {
      // this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      if (this.activeFilters.settled === '0') {
        this.activeFilters.date_from = '';
      } else {
        this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      }
      this.activeFilters.date_to = moment().format('YYYY-MM-DD');
    },
    nextMonth() {
      if (this.activeFilters.date_from !== '') {
        this.activeFilters.date_from = moment(this.activeFilters.date_from).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      }
      if (this.activeFilters.date_to !== '') {
        this.activeFilters.date_to = moment(this.activeFilters.date_to).add(1, 'months').endOf('month').format('YYYY-MM-DD');
      }
    },
    setReportType(settled) {
      if (settled !== this.activeFilters.settled) {
        if (settled === '0') {
          this.activeFilters.date_from = '';
        } else {
          if (this.activeFilters.date_to) {
            this.activeFilters.date_from = moment(this.activeFilters.date_to).startOf('month').format('YYYY-MM-DD');
          } else {
            this.setToday();
          }
        }
        this.activeFilters.settled = settled;
        this.getReport();
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.setToday();
      this.getReport();
    },
    setUrl() {
      const query = {};
      Object.keys(this.activeFilters).forEach((key) => {
        query[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'InkasoReport', query });
      }
    },
    checkAll() {
      if (this.checkedAll) {
        this.rows.forEach((obj) => { obj.checked = '0'; });
      } else {
        this.rows.forEach((obj) => { obj.checked = '1'; });
      }
    },
    setSettled(id) {
      if (this.activeFilters.settled === '0') {
        this.confirm(() => this.confirmedSetSettled(id), 'Potwierdź rozliczenie gotówki wybranej raty.');
      }
    },
    confirmedSetSettled(id) {
      this.$store.dispatch('setLoadingData', true);
      const formData = new FormData();
      formData.append('inst_ids[]', id);
      OrderService.setInstallmentsAsSettled(formData)
        .then((response) => {
          this.rows = this.rows.filter(obj => obj.id !== id);
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    setSettledSelected() {
      this.confirm(() => this.confirmedSetSettledSelected(), 'Potwierdź rozliczenie gotówki dla zaznaczonych rat.');
    },
    confirmedSetSettledSelected() {
      this.$store.dispatch('setLoadingData', true);
      const formData = new FormData();
      this.rows.forEach((obj) => {
        if (obj.checked === '1') {
          formData.append('inst_ids[]', obj.id);
        }
      });
      OrderService.setInstallmentsAsSettled(formData)
        .then((response) => {
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          this.$store.dispatch('setLoadingData', false);
          this.getReport();
        })
        .catch((error) => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
  },
};
</script>
